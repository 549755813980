import React, { useContext } from 'react'
import {
  Wrapper,
  Container,
  ImageWrapper,
  Name,
  Price,
  Tail,
  Item,
} from './styles'
import { Link } from 'gatsby'
import { Img } from '../../utils/styles'
import StoreContext from '../../context/StoreContext'

const ProductSlide = ({ itemList }) => {
  const {
    store: { checkout },
  } = useContext(StoreContext)

  const getPrice = price =>
    Intl.NumberFormat(undefined, {
      currency: checkout.currencyCode ? checkout.currencyCode : 'EUR',
      style: 'currency',
    }).format(parseFloat(price ? price : 0))

  return (
    <Wrapper>
      <Container>
        {itemList.map(
          ({
            id,
            handle,
            shopifyId,
            title,
            images: [firstImage],
            variants: [firstVariant],
          }) => {
            return (
              <Item key={id}>
                <Link to={`/product/${shopifyId}/`}>
                  <ImageWrapper>
                    {firstImage && firstImage.localFile && (
                      <Img
                        fluid={firstImage.localFile.childImageSharp.fluid}
                        alt={handle}
                      />
                    )}
                  </ImageWrapper>
                  <Tail>
                    <Name>{title}</Name>
                    <Price>{getPrice(firstVariant.price)}</Price>
                  </Tail>
                </Link>
              </Item>
            )
          }
        )}
      </Container>
    </Wrapper>
  )
}

export default ProductSlide
