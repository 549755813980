import styled from '@emotion/styled'
import colors from '../../styles/color'

export const Wrapper = styled.div`
  margin-bottom: 16px;
`

export const Container = styled.div`
  overflow: scroll;
  overflow-scrolling: touch;
  display: flex;
  align-items: center;
  background-color: #f7f7f7;
  padding: 0 14px;

  &::-webkit-scrollbar {
    display: none;
  }
`

export const Item = styled.div`
  margin-right: 5px;
  text-align: center;
  width: 200px;
`

export const ImageWrapper = styled.div`
  width: 200px;
`

export const Tail = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-weight: 700;
  margin-bottom: 15px;
  padding: 0 4px;
`

export const Name = styled.div`
  color: ${colors.GRAY};
  font-size: 14px;
`

export const Price = styled.div`
  font-size: 14px;
  color: ${colors.GRAY};
`
