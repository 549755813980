import React from 'react'
import { EnLabel, Label, Container } from './styles'

const ProductTitle = ({ en, label }) => {
  return (
    <Container>
      <Label>{label}</Label>
      <EnLabel>{en}</EnLabel>
    </Container>
  )
}

export default ProductTitle
