import styled from '@emotion/styled'

export const Container = styled.div`
  margin-bottom: 30px;
  font-weight: 700;
  padding-left: 12px;
`

export const EnLabel = styled.div`
  font-family: linotype-didot, serif;
  font-size: 15px;
  padding-left: 2px;
`

export const Label = styled.div`
  font-size: 20px;
  padding-left: 2px;
`
