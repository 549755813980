import React from 'react'
import SEO from '~/components/seo'
import Image from '../components/image'
import CategoryTitle from '../components/Title/CategoryTitle'
import {
  Container,
  LinkWrapper,
  MoreLink,
  PickupContainer,
  Text,
  HeadContainer,
  ImageWrapper,
  PickupByCategory,
  PickupByCategoryHead,
  Wrapper,
} from '../styles/accessoryPage'
import ProductTitle from '../components/Title/ProductTitle'
import ProductSlide from '../components/ProductSlide'
import { graphql, useStaticQuery } from 'gatsby'
import PageTransition from '../components/Animation/PageTransition'

const AccessoryPage = () => {
  const { allShopifyProduct } = useStaticQuery(
    graphql`
      query {
        allShopifyProduct(sort: { fields: [createdAt], order: DESC }) {
          group(field: variants___product___productType, limit: 5) {
            edges {
              node {
                tags
                id
                title
                handle
                shopifyId
                createdAt
                images {
                  id
                  originalSrc
                  localFile {
                    childImageSharp {
                      fluid(maxWidth: 910) {
                        ...GatsbyImageSharpFluid_withWebp_tracedSVG
                      }
                    }
                  }
                }
                variants {
                  price
                }
                productType
              }
            }
          }
        }
      }
    `
  )

  const productNodeList = allShopifyProduct.group.flatMap(({ edges }) => edges)

  const braceletList = productNodeList
    .map(item => item.node)
    .filter(item => item.productType === 'ブレスレット')

  const designBraceletList = productNodeList
    .map(item => item.node)
    .filter(item => item.productType === 'デザインブレスレット')

  const necklaceList = productNodeList
    .map(item => item.node)
    .filter(item => item.productType === 'ネックレス')

  const stoneList = productNodeList
    .map(item => item.node)
    .filter(item => item.productType === '原石')

  return (
    <Wrapper>
      <SEO
        title="アクセサリーページ"
        keywords={[`高天原`, `アクセサリー`, `天然石`, `accessory`, `stone`]}
      />
      <div></div>
      <Container>
        <Image filename="photo/kimono.jpg" />
        <HeadContainer>
          <CategoryTitle en="Accessory" label="アクセサリー" />
          <Text>
            上品で洗練された美しさ。
            <br />
            厳選した上質な天然石を使用したシンプルなデザイン。
            和をテーマにしたオリジナルデザイン。
          </Text>
        </HeadContainer>

        {/*ブレスレット*/}
        <PickupByCategory>
          <PickupByCategoryHead>
            <ImageWrapper>
              <Image filename="photo/c_bracelet.jpg" alt="天然石・パワーストーンの一連ブレスレット" />
            </ImageWrapper>
          </PickupByCategoryHead>

          {/*一連ブレスレット*/}
          <PickupContainer>
            <ProductTitle en="Bracelet" label="一連ブレスレット" />
            <Text>
              厳選した品質の良いビーズを使用しています。
              <br />
              天然石の魅力を楽しめるブレスレット。
              <br />
            </Text>
            <ProductSlide itemList={braceletList} />
            <LinkWrapper>
              <MoreLink to="/bracelet-list">すべて見る</MoreLink>
            </LinkWrapper>
          </PickupContainer>

          {/*デザインブレスレット*/}
          <PickupContainer>
            <ProductTitle en="Design Bracelet" label="デザインブレスレット" />
            <Text>
              「神は細部に宿る」1つ1つのパーツに拘りました。和をイメージしたデザイン。
            </Text>
            <ProductSlide itemList={designBraceletList} />
            <LinkWrapper>
              <MoreLink to="/designbracelet-list">すべて見る</MoreLink>
            </LinkWrapper>
          </PickupContainer>
        </PickupByCategory>

        {/*ネックレス*/}
        <PickupByCategory>
          <PickupByCategoryHead>
            <ImageWrapper>
              <Image filename="photo/c_necklace.jpg" alt="天然石・パワーストーンのネックレス" />
            </ImageWrapper>
          </PickupByCategoryHead>
          <PickupContainer>
            <ProductTitle en="Necklace" label="ネックレス" />
            <Text>天然石の魅力を引き立てるシンプルなデザイン。</Text>
            <ProductSlide itemList={necklaceList} />
            <LinkWrapper>
              <MoreLink to="/necklace-list">すべて見る</MoreLink>
            </LinkWrapper>
          </PickupContainer>
        </PickupByCategory>

        {/*原石*/}
        <PickupByCategory>
          <PickupByCategoryHead>
            <ImageWrapper>
              <Image filename="photo/stone.jpg" alt="天然石・パワーストーンの原石" />
            </ImageWrapper>
          </PickupByCategoryHead>
          <PickupContainer>
            <ProductTitle en="Stones" label="原石" />
            <Text>
              自然が生み出した芸術品。空間やアクセサリーの浄化にもお勧め。
            </Text>
            <ProductSlide itemList={stoneList} />
            <LinkWrapper>
              <MoreLink to="/stone-list">すべて見る</MoreLink>
            </LinkWrapper>
          </PickupContainer>
        </PickupByCategory>
      </Container>
      <PageTransition />
    </Wrapper>
  )
}

export default AccessoryPage
